<template>
  <div>
    <div class="page-title pageheading">
      <div class="panel-heading pl-4 pb-7">
        <h3>
          <span id="MainContent_lblReportName">Client Census Summary</span>
        </h3>

        <span>Total Number of clients by payer and status to date</span>
      </div>
    </div>

    <div class="container mt-10" style="background-color: #ffffff">
      <table id="tab">
        <th>Payer</th>
        <th>Active</th>
        <th>In Active</th>
        <th>Total</th>
        <tr v-for="(data, ndx) in tableData" :key="ndx">
          <td>
            {{ data.companyName }}
          </td>
           <td>{{ data.activeClient }}</td>
          <td>{{ data.inActiveClient }}</td>
          <td>
              {{ data.total }}
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            {{ totalActiveValue }}
          </td>
          <td>
            {{ totalInActiveValue }}
          </td>
          <td>{{ totalValue }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return { tableData: [] };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapGetters(["getCensus"]),
    totalActiveValue() {
      return this.tableData.reduce((total, a) => total + a.activeClient, 0);
    },
    totalInActiveValue() {
      return this.tableData.reduce((total, a) => total + a.inActiveClient, 0);
    },
    totalValue() {
      return this.tableData.reduce(
        (total, a) => total + (a.inActiveClient  + a.activeClient),
        0
      );
    },
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("getCensusSummary").then((r) => {
        // r.forEach((x, y) => {
        //   if (this.tableData.some((z) => z.payersId == x.payersId)) {
        //     this.tableData.map((z) => {
        //       if (z.payersId == x.payersId) {
        //         if (x.status == 'InActive') {
        //           z.inActive += 1;
        //         } else {
        //           z.active += 1;
        //         }
        //       }
        //     });
        //   } else { 
        //     if (x.status == 'InActive') { 
        //       this.tableData.push({
        //         companyName: x.companyName,
        //         active: 0,
        //         inActive: 1,
        //         payersId: x.payersId,
        //       });
        //     } else {
        //       this.tableData.push({
        //         companyName: x.companyName,
        //         active: 1,
        //         inActive: 0,
        //         payersId: x.payersId,
        //       });
        //     }
        //   }
        // });
        this.tableData= r
      });
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 90px;
  background-color: #ffffff;
}
.panel-body {
  background-color: #ffffff !important;
  padding: 10px;
  margin-top: 20px;
}
strong {
  padding-top: 10px;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#tab {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#tab th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  border-bottom: 1px solid;
  border-color: #1b5d77;
}
#tab tr {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  border-bottom-style: solid;
  border-bottom-width: thin;
   line-height: 1.6;
}
</style>
